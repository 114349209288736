@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes maskFromTop {
  from {
    max-height: 0;
  }
  to {
    max-height: 300px;
  }
}

@keyframes scale {
  0% {
    transform: scale(1, 1);
  }
  45% {
    transform: scale(1, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(170deg);
  }
  50% {
    transform: rotate(340deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes blink {
  0% {
    opacity: 0.5;
  }
  45% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes slideIn {
  0% {
    margin-top: -20rem;
  }
  70% {
    opacity: 1;
    margin-top: 1rem;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes blendIn {
  0% {
    opacity: 0;
    display: flex;
  }
  100% {
    opacity: 1;
    display: flex;
  }
}

@keyframes bounce {
  0% {
    margin-top: -0.5rem;
  }
  100% {
    margin-top: 0.75rem;
  }
}

@keyframes blendOut {
  0% {
    opacity: 1;
    display: flex;
  }
  99% {
    opacity: 0;
    display: flex;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blowing {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes blowOut {
  0% {
    display: inline;
    opacity: 1;
  }
  95% {
    opacity: 1;
    display: inline;
    transform: scale(10);
  }
  100% {
    opacity: 0;
    display: none;
    transform: scale(10);
  }
}

@keyframes ov_slide_in {
  0% {
    opacity: 0;
    transform: matrix(1, 0, 0, 1, 0, 200);
  }
  100% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

@keyframes bump_in {
  0% {
    transform: scale(2.5);
  }
  80% {
    opacity: 1;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

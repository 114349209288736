html {
  background-color: #000;
  //height: 100%;
  //max-height: 100vh;
}

body {
  margin: 0;
  height: inherit;
  overflow-x: hidden;
}

html,
body {
  //height: 100%;
  width: 100%;
  background: #111111;
}

#root {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

::selection {
  color: black;
  background: #ecd693;
}

.primary {
  ::selection {
    color: #ecd693;
    background: black;
  }
}
